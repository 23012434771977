<template>
    <div>
    <router-link to="/auth/login">Sign In</router-link>
    </div>
</template>

<script>
export default {
    name: 'Home',

    components: {        
    },
}
</script>
